.App {
  margin-top: 4rem;
  height: 100vh;
  word-break: break-word;
}
.box {
  padding-top: 1em;
  padding-left: 4em;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.MuiBreadcrumbs-separator{
  margin-left: 0 !important;
  margin-right: 0 !important
}
.ant-result-subtitle{
  color: rgba(0, 0, 0, 0.85) !important;
  font-size: 18px !important;
}

/*** Text editor *****/
.home-editor{
  border: 1px solid #f1f1f1 !important;
    padding: 5px !important;
    border-radius: 2px !important;
    height: 80% !important;
}

.rdw-dropdown-selectedtext{
  color:black;
}

.ant-form-item-label  > label{
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s; 
}
.ant-form-item{
margin-bottom: 0px !important;
}

/* #loader {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(155, 155, 155, 0.7);
}

.clickable {
  cursor: pointer;
}
#outlined-size-small {
  height: 3px;
}
.react-pdf__Document {
  box-shadow: 2px 2px 8px 0 rgb(0 0 0 / 20%);
  margin: 0 auto;
  position: relative;
} 

.control-panel {
  font-size: 16px;
  color: black;
  background-color: white;
  border-radius: 5px;
  width: fit-content;
}*/


.anticon {
  display: inline-flex !important;
  align-items: center !important;
}