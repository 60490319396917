body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.disabled {
  cursor: not-allowed;
  color: lightgray;
}

@media (max-width:600px)
{
  .rpv-default-layout-sidebar{
    display: none;
  }
  
}

#description_module:focus{
  border-color: #2c387e;
}
#description_module:hover{
  border-color: #2c387e;
}


@font-face { 
  font-family: "Telegraf";
  src: local("Telegraf"),
   url("./fonts/FontsFree-Net-Telegraf-Regular.ttf") format("truetype");
  font-weight: bold;
  }